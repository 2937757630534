import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';

const slides = [
  {
    backgroundImage: 'https://wallpapers.com/images/hd/kylo-ren-first-order-hero-i6zhpg9a5abnjcem.jpg',
    title: 'Kylo Ren & Rey Premium Format Figure Set',
    description: 'Hemen Satın Al',
    buttonUrl: '/plp',
  },
  {
    backgroundImage: 'https://w0.peakpx.com/wallpaper/103/381/HD-wallpaper-2021-the-batman-batman.jpg',
    title: 'Batman & Joker Collectible Figures',
    description: 'Hemen Satın Al',
    buttonUrl: '/plp',
  },
  {
    backgroundImage: 'https://wallpapers.com/images/featured/avengers-vm16xv4a69smdauy.jpg',
    title: 'Marvel Avengers Set',
    description: 'Hemen Satın Al',
    buttonUrl: '/plp',
  },
];

const Hero = () => {
  const navigate = useNavigate();

  return (
    <section className="relative bg-[#191919] text-white h-[430px] overflow-hidden">
      <Swiper
        modules={[Autoplay]} // Pagination kaldırıldı
        autoplay={{ delay: 3000 }}
        slidesPerView={1}
        className="h-full w-full"
        spaceBetween={0}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {/* Tüm slaytı tıklanabilir hale getiriyoruz */}
            <div
              className="relative w-full h-full cursor-pointer"
              onClick={() => navigate(slide.buttonUrl)}
              style={{
                backgroundImage: `url(${slide.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              {/* Arka Plan Üstü Siyah Overlay */}
              <div className="absolute inset-0 bg-[#191919] opacity-80"></div>
              
              {/* Sağ Alta Sabitlenen İçerik */}
              <div className="absolute bottom-10 right-10 text-right max-w-md lg:mr-36">
                <h1 className="text-3xl md:text-5xl font-bold mb-2">
                  {slide.title}
                </h1>
                <p className="text-sm md:text-lg mb-4">
                  {slide.description}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Hero;
