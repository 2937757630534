import React, { useState } from "react";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Swal from "sweetalert2";

const SignInWithPhone = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);

  // Firebase Auth nesnesini başlatıyoruz
  const auth = getAuth();

  // Firebase doğrulama dilini Türkçe olarak ayarlıyoruz
  auth.languageCode = 'tr'; // Kullanıcı diline göre ayarlamak için auth.useDeviceLanguage() de kullanılabilir.

  // reCAPTCHA doğrulayıcıyı ayarlama fonksiyonu
  const setupRecaptcha = () => {
    console.log("reCAPTCHA ayarlanıyor...");
    if (!window.recaptchaVerifier) {
      try {
        // Görünmez reCAPTCHA kullanıyoruz
        window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA doğrulandı.");
            sendVerificationCode();
          },
          "expired-callback": () => {
            Swal.fire("Uyarı", "reCAPTCHA süresi doldu, lütfen tekrar deneyin.", "warning");
            console.log("reCAPTCHA süresi doldu.");
          }
        }, auth);
      } catch (error) {
        console.error("reCAPTCHA başlatılamadı:", error);
      }
    }
  };

  // Telefon numarasına doğrulama kodunu gönderme fonksiyonu
  const sendVerificationCode = () => {
    console.log("Doğrulama kodu gönderilmeye hazırlanıyor...");

    // Telefon numarasını doğruluyoruz
    if (!isValidPhoneNumber(phoneNumber)) {
      Swal.fire("Hata", "Lütfen geçerli bir Türkiye telefon numarası girin.", "error");
      console.error("Geçersiz telefon numarası:", phoneNumber);
      return;
    }

    setupRecaptcha();

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((result) => {
        setConfirmationResult(result);
        Swal.fire("Başarılı", "Doğrulama kodu gönderildi.", "success");
        console.log("Doğrulama kodu gönderildi:", result);
      })
      .catch((error) => {
        console.error("SMS gönderilemedi:", error);
        Swal.fire("Hata", "SMS gönderilemedi. Lütfen tekrar deneyin.", "error");
      });
  };

  // Kullanıcıyı doğrulama kodu ile giriş yaptırma fonksiyonu
  const verifyCode = () => {
    console.log("Doğrulama kodu doğrulanıyor...");

    if (confirmationResult && verificationCode) {
      confirmationResult.confirm(verificationCode)
        .then((result) => {
          Swal.fire("Başarılı", "Giriş başarılı!", "success");
          const user = result.user;
          console.log("Giriş başarılı. Kullanıcı:", user);
        })
        .catch((error) => {
          console.error("Doğrulama kodu hatalı:", error);
          Swal.fire("Hata", "Doğrulama kodu hatalı. Lütfen tekrar deneyin.", "error");
        });
    } else {
      Swal.fire("Hata", "Doğrulama kodu eksik. Lütfen tekrar deneyin.", "error");
      console.error("Doğrulama kodu eksik veya confirmationResult bulunamadı.");
    }
  };

  // Telefon numarası doğrulama fonksiyonu
  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^\+90\d{10}$/; // Türkiye için telefon numarası formatı
    return phoneRegex.test(phone);
  };

  return (
    <div className="sign-in-container">
      <h2>Telefon Numarası ile Giriş Yap</h2>
      <div>
        <label>Telefon Numarası:</label>
        <input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="+90 555 555 5555"
          required
        />
        <button
          id="sign-in-button"
          onClick={sendVerificationCode}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Doğrulama Kodunu Gönder
        </button>
      </div>

      <div style={{ marginTop: "20px" }}>
        <label>Doğrulama Kodu:</label>
        <input
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Doğrulama kodunu girin"
          required
        />
        <button
          onClick={verifyCode}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Giriş Yap
        </button>
      </div>

      {/* reCAPTCHA için container */}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default SignInWithPhone;
