import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';

function Navbar() {
  const [cartOpen, setCartOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const cartRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(storedCartItems);
  }, [cartOpen]);

  const toggleCart = () => {
    setCartOpen(!cartOpen);
    if (!cartOpen) {
      setUserMenuOpen(false);
    }
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
    if (!userMenuOpen) {
      setCartOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error('Çıkış yapma hatası:', error);
      });
  };

  const handleRemoveFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setCartOpen(false);
      }
    };

    if (cartOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cartOpen]);

  const truncateName = (name, maxLength = 20) => {
    return name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
  };

  return (
    <nav className="bg-white antialiased shadow-md">
      <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
        <div className="flex items-center justify-between py-4 lg:py-6">
          {/* Mobil Menu Butonu */}
          <button
            onClick={toggleMenu}
            className="inline-flex items-center bg-red-600 lg:hidden p-2 text-white hover:bg-gray-100 rounded-lg"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              {menuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              )}
            </svg>
          </button>
          <ul
          className={`hidden lg:flex items-center justify-start gap-6 md:gap-8 py-3 sm:justify-center`}
        >
          <li>
            <Link
              to="/plp"
              className="flex text-sm font-medium text-gray-900 hover:text-primary-700"
            >
              Ürünler
            </Link>
          </li>
          <li>
            <Link
              to="/gift-ideas"
              className="flex text-sm font-medium text-gray-900 hover:text-primary-700"
            >
              Ön Siparişler
            </Link>
          </li>
          <li className="shrink-0 hidden sm:flex">
            <Link
              to="/takas"
              className="text-sm font-medium text-gray-900 hover:text-primary-700"
            >
              Takas
            </Link>
          </li>
        </ul>

          {/* Logo */}
          <div className="flex-grow flex justify-center lg:justify-center">
            <Link to="/" title="">
              <img
                className="block w-auto h-12 lg:h-20"
                src="https://firebasestorage.googleapis.com/v0/b/elmalicollectibles-87dc4.appspot.com/o/eclogo.svg?alt=media&token=90d0a55e-00e8-4ef0-a575-d0902091b4c0"
                alt="Logo"
              />
            </Link>
          </div>

          {/* Mobil İkonlar */}
          <div className="lg:hidden flex items-center space-x-4">
            <div className="relative">
              <button
                onClick={toggleCart}
                className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
              >
                <span className="sr-only">Sepet</span>
                <div className="relative">
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 100 4 2 2 0 000-4Zm8 0a2 2 0 100 4 2 2 0 000-4Zm-8.5-3h9.25L19 7H7.312"
                    />
                  </svg>
                  {cartItems.length > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-medium text-white bg-red-700 rounded-full -top-1.5 -end-1.5">
                      {cartItems.length}
                    </div>
                  )}
                </div>
              </button>
            </div>

            <div className="relative">
              {user ? (
                <button
                  onClick={toggleUserMenu}
                  className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeWidth="2"
                      d="M7 17v1a1 1 0 001 1h8a1 1 0 001-1v-1a3 3 0 00-3-3h-4a3 3 0 00-3 3Zm8-9a3 3 0 11-6 0 3 3 0 016 0Z"
                    />
                  </svg>
                </button>
              ) : (
                <Link
                  to="/login"
                  className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path stroke="currentColor" strokeWidth="2" d="M12 4v16m8-8H4" />
                  </svg>
                </Link>
              )}
            </div>
          </div>

          {/* Sağ İkonlar: Sepet ve Kullanıcı */}
          <div className="hidden lg:flex items-center space-x-4">
            <div className="relative">
              <button
                onClick={toggleCart}
                className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
              >
                <span className="sr-only">Sepet</span>
                <div className="relative">
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 4h1.5L9 16m0 0h8m-8 0a2 2 0 100 4 2 2 0 000-4Zm8 0a2 2 0 100 4 2 2 0 000-4Zm-8.5-3h9.25L19 7H7.312"
                    />
                  </svg>
                  {cartItems.length > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-medium text-white bg-red-700 rounded-full -top-1.5 -end-1.5">
                      {cartItems.length}
                    </div>
                  )}
                </div>
                <span className="hidden lg:flex ml-2">Sepetim</span>
              </button>
            </div>

            {/* Kullanıcı Hesabı */}
            <div className="relative">
              {user ? (
                <button
                  onClick={toggleUserMenu}
                  className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeWidth="2"
                      d="M7 17v1a1 1 0 001 1h8a1 1 0 001-1v-1a3 3 0 00-3-3h-4a3 3 0 00-3 3Zm8-9a3 3 0 11-6 0 3 3 0 016 0Z"
                    />
                  </svg>
                  <span className="hidden lg:flex ml-2">Hesabım</span>
                </button>
              ) : (
                <Link
                  to="/login"
                  className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
                >
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path stroke="currentColor" strokeWidth="2" d="M12 4v16m8-8H4" />
                  </svg>
                  <span className="hidden lg:flex ml-2">Giriş Yap</span>
                </Link>
              )}
            </div>
          </div>
        </div>

        {/* Menü Linkleri (PC Görünümü) */}
   

        {/* Hamburger Menüsü Tam Ekran */}
        {menuOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex flex-col justify-center items-center">
            <button
              onClick={closeMenu}
              className="absolute top-4 right-4 text-white text-4xl"
            >
              &times;
            </button>
            <div className="flex flex-col items-center space-y-6">
              <Link
                to="/plp"
                onClick={closeMenu}
                className="text-white text-2xl font-semibold"
              >
                Ürünler
              </Link>
              <Link
                to="/gift-ideas"
                onClick={closeMenu}
                className="text-white text-2xl font-semibold"
              >
                Ön Siparişler
              </Link>
              <Link
                to="/takas"
                onClick={closeMenu}
                className="text-white text-2xl font-semibold"
              >
                Takas
              </Link>
              <Link
                to="/cart"
                onClick={closeMenu}
                className="text-white text-2xl font-semibold"
              >
                Sepetim
              </Link>
              {user ? (
                <>
                  <Link
                    to="/profile"
                    onClick={closeMenu}
                    className="text-white text-2xl font-semibold"
                  >
                    Hesabım
                  </Link>
                  <button
                    onClick={() => {
                      handleSignOut();
                      closeMenu();
                    }}
                    className="text-white text-2xl font-semibold"
                  >
                    Çıkış Yap
                  </button>
                </>
              ) : (
                <Link
                  to="/login"
                  onClick={closeMenu}
                  className="text-white text-2xl font-semibold"
                >
                  Giriş Yap
                </Link>
              )}
            </div>
          </div>
        )}

        {/* Arama Çubuğu */}
        <div className="hidden lg:flex lg:justify-center py-4">
          <form action="#" className="w-full max-w-lg">
            <label htmlFor="search" className="sr-only">
              Ara
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                name="search"
                id="search"
                className="block w-full pl-10 pr-4 py-2 text-sm text-gray-900 bg-gray-100 rounded-full border border-gray-300 focus:ring-2 focus:ring-primary-600 focus:border-primary-600 focus:outline-none"
                placeholder="Ürün ara..."
                required
              />
            </div>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
