import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase projenizin yapılandırma bilgileri
const firebaseConfig = {
    apiKey: "AIzaSyCBq_npk8vGTqxdnvFALT8C9XC9uLL5Mys",
    authDomain: "elmalicollectibles-87dc4.firebaseapp.com",
    projectId: "elmalicollectibles-87dc4",
    storageBucket: "elmalicollectibles-87dc4.appspot.com",
    messagingSenderId: "256397100066",
    appId: "1:256397100066:web:0abc11d774116f9cabb67b"
};

// Firebase uygulamasını başlatıyoruz
const app = initializeApp(firebaseConfig);

// Firebase Auth, Firestore ve Storage örneklerini oluşturuyoruz
const auth = getAuth(app); 
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();


export { auth, db, storage, googleProvider };

