import React from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ product }) {
  return (
    <Link 
      to={`/product/${product.id}`} 
      className={`group text-sm relative ${product.stock === 0 ? 'opacity-50' : ''}`} // Opaklığı azaltıyoruz
    >
      {/* Ürün Resmi */}
      <div className="aspect-square w-full overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75 relative">
        <img
          alt={product.productName}
          src={product.coverImageUrl}
          className="h-full w-full object-cover object-center"
        />

        {/* Stokta Yok Badge'i */}
        {product.stock === 0 && (
          <div className="absolute top-2 right-2 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded">
            Stokta Yok
          </div>
        )}
      </div>

      {/* Ürün İsmi - Sola hizalı ve kısaltılmış */}
      <h3
        className="mt-4 font-medium text-white text-left truncate"
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      >
        {product.productName}
      </h3>

      {/* Ürün Fiyat ve İndirim Durumu */}
      <div className="mt-2 text-left">
        {product.discount > 0 ? (
          <div className="flex items-center">
            {/* Orijinal fiyatı üstü çizili */}
            <span className="line-through text-gray-600">
              {product.price.toFixed(2)} ₺
            </span>
            {/* İndirimli fiyat */}
            <span className="ml-2 text-green-600 font-semibold">
              {(product.price * (100 - product.discount) / 100).toFixed(2)} ₺
            </span>
          </div>
        ) : (
          <span className="text-white font-semibold">
            {product.price.toFixed(2)} ₺
          </span>
        )}
      </div>
    </Link>
  );
}

export default ProductCard;
