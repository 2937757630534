import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore/lite';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // React 16 and above requires this for accessibility

function Checkout() {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [originalTotalPrice, setOriginalTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [checkoutFormHtml, setCheckoutFormHtml] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state

  const handlePayment = async () => {
    try {
      const response = await fetch('https://us-central1-elmalicollectibles-87dc4.cloudfunctions.net/createPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          totalPrice: 100.00,
          buyer: {
            id: 'BY789',
            firstName: 'Adınız',
            lastName: 'Soyadınız',
            phoneNumber: '5555555555',
            email: 'email@example.com',
            identityNumber: '11111111111',
            address: 'Adresiniz',
            city: 'Şehir',
            country: 'Türkiye',
          },
          basketItems: [
            {
              id: 'item1',
              name: 'Ürün 1',
              category: 'Kategori',
              price: 50.00,
            },
            {
              id: 'item2',
              name: 'Ürün 2',
              category: 'Kategori',
              price: 50.00,
            },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.status} ${response.statusText}`);
      }

      const contentType = response.headers.get('Content-Type');
      let data;

      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
        console.log('Response Data:', data);
      } else {
        const text = await response.text();
        throw new Error(`Unexpected response format: ${text}`);
      }

      if (data.checkoutFormContent) {
        setCheckoutFormHtml(data.checkoutFormContent);
        setModalIsOpen(true); // Open the modal when payment form is ready
      } else {
        throw new Error('Ödeme formu oluşturulamadı.');
      }
    } catch (error) {
      console.error('Payment error:', error);
    }
  };

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    fetchProductImages(cart);
    calculateTotal(cart);
  }, []);

  const fetchProductImages = async (cart) => {
    const updatedCart = await Promise.all(
      cart.map(async (item) => {
        const productDoc = doc(db, 'products', item.id);
        const productSnapshot = await getDoc(productDoc);
        if (productSnapshot.exists()) {
          return { ...item, imageUrl: productSnapshot.data().coverImageUrl };
        }
        return item;
      })
    );
    setCartItems(updatedCart);
  };

  const calculateTotal = (cart) => {
    let originalTotal = 0;
    let discountedTotal = 0;

    cart.forEach((item) => {
      const itemOriginalTotal = item.price * item.quantity;
      const itemDiscountedTotal =
        item.discount > 0
          ? (item.price * (100 - item.discount)) / 100 * item.quantity
          : itemOriginalTotal;

      originalTotal += itemOriginalTotal;
      discountedTotal += itemDiscountedTotal;
    });

    setOriginalTotalPrice(originalTotal.toFixed(2));
    setTotalPrice(discountedTotal.toFixed(2));
    setTotalDiscount((originalTotal - discountedTotal).toFixed(2));
  };

  const createIframe = () => {
    if (!checkoutFormHtml) return null;

    return (
      <iframe
        style={{ width: '100%', height: '500px', border: 'none' }}
        srcDoc={`<html><body>${checkoutFormHtml}</body></html>`}
        sandbox="allow-scripts allow-same-origin"
      />
    );
  };

  return (
    <section className="bg-gray-900 py-8 antialiased md:py-16">
      <form action="#" className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <h2 className="text-xl font-semibold text-white sm:text-2xl">Ödeme</h2>

        <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-6">
          <div className="w-full space-y-4 sm:space-y-6 lg:max-w-sm xl:max-w-lg">
            {/* Fatura Adresi Formu */}
            {/* Diğer form alanları burada olacak */}
          </div>

          <div className="mt-6 min-w-0 flex-1 space-y-6 sm:mt-8 lg:mt-0">
            <div className="space-y-4 sm:space-y-6">
              {cartItems.map((item, index) => (
                <div
                  key={index}
                  className="rounded-lg border dark:border-gray-700 dark:bg-gray-800 p-6 shadow-sm"
                >
                  <div className="space-y-4 sm:space-y-6 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                    <a href="#" className="aspect-square w-20 shrink-0 block">
                      <img
                        className="h-full w-full"
                        src={item.imageUrl}
                        alt={item.productName}
                      />
                    </a>

                    <div className="w-full min-w-0 flex-1 space-y-4 md:max-w-md">
                      <a
                        href="#"
                        className="text-base font-medium text-white hover:underline"
                      >
                        {item.productName}
                      </a>
                    </div>

                    <div className="flex items-center justify-between gap-4 md:justify-end">
                      <div className="w-8 shrink-0">
                        <p className="text-base font-normal text-white">
                          x{item.quantity}
                        </p>
                      </div>

                      <div className="text-right md:w-32">
                        <p className="text-base font-bold text-white">
                          {(item.price * item.quantity).toFixed(2)} ₺
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="space-y-4 rounded-lg border dark:border-gray-700 dark:bg-gray-800 p-4 shadow-sm sm:p-6">
                <p className="text-xl font-semibold text-white">Sipariş Özeti</p>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-400">
                        Orijinal Fiyat
                      </dt>
                      <dd className="text-base font-medium text-white">
                        {originalTotalPrice} ₺
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-400">
                        İndirim
                      </dt>
                      <dd className="text-base font-medium text-green-500">
                        -{totalDiscount} ₺
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4 border-t border-gray-700 pt-2">
                      <dt className="text-base font-bold text-white">Toplam</dt>
                      <dd className="text-base font-bold text-white">
                        {totalPrice} ₺
                      </dd>
                    </dl>
                  </div>

                  <button
                    type="button"
                    className="mb-2 me-2 flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    onClick={handlePayment}
                  >
                    Ödemeye Devam Et
                  </button>

                  <div className="flex items-center justify-center gap-2">
                    <span className="text-sm font-normal text-gray-400">
                      {' '}
                      veya{' '}
                    </span>
                    <a
                      href="#"
                      title=""
                      className="inline-flex items-center gap-1 text-sm font-medium text-gray-500 underline hover:no-underline"
                    >
                      Alışverişe Dön
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 12H5m14 0-4 4m4-4-4-4"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* İyzico Checkout Form İçeriği Modal ile */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={{
            content: {
              width: '80%',
              height: '80%',
              margin: 'auto',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
          }}
        >
          {createIframe()}
        </Modal>
      </form>
    </section>
  );
}

export default Checkout;
