  import React, { useState } from 'react';
  import { auth } from './firebaseConfig';
  import { createUserWithEmailAndPassword } from 'firebase/auth';
  import { useNavigate } from 'react-router-dom';

  function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [kvkkApproved, setKvkkApproved] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');

      if (password !== confirmPassword) {
        setError('Şifreler eşleşmiyor!');
        return;
      }

      if (!kvkkApproved) {
        setError('Lütfen KVKK onayını kabul edin.');
        return;
      }

      try {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate('/');
      } catch (error) {
        setError('Kayıt işlemi başarısız: ' + error.message);
      }
    };

    return (
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg dark:bg-gray-800">
        <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">Kayıt Ol</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Şifre</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">Şifreyi Onayla</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={kvkkApproved}
              onChange={(e) => setKvkkApproved(e.target.checked)}
              className="h-4 w-4 text-primary-600 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
            />
            <label className="ml-2 text-sm text-gray-600 dark:text-gray-400">
              KVKK Onay Formu'nu okudum ve kabul ediyorum.
            </label>
          </div>
          {error && <p className="text-sm text-red-500">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 dark:bg-primary-600 dark:hover:bg-primary-700"
          >
            Kayıt Ol
          </button>
        </form>
      </div>
    );
  }

  export default Register;
