import React, { useState } from 'react';
import { auth, googleProvider } from './firebaseConfig'; // Firebase v9 uyumlu
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'; // v9 modüler yapı
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError('Giriş işlemi başarısız: ' + error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/');
    } catch (error) {
      setError('Google ile giriş başarısız: ' + error.message);
    }
  };

  return (
 <>
    <div
      className="min-h-screen flex items-center justify-center bg-cover bg-center relative"
      style={{
        backgroundImage: `url('https://img.freepik.com/premium-photo/tom-bombadil-having-walk-forest-night-blue-moon-sky-colored-with-pencil_301641-17.jpg')`,
      }}
    >
      {/* Siyah, %50 opaklık katmanı */}
      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>

      {/* Login formu */}
      <div className="relative z-10 max-w-md w-full p-6 bg-white rounded-lg shadow-lg dark:bg-gray-800 bg-opacity-90">
        <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white text-center">
          Giriş Yap
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              Şifre
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-300"
              required
            />
          </div>
          {error && <p className="text-sm text-red-500">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 dark:bg-primary-600 dark:hover:bg-primary-700"
          >
            Giriş Yap
          </button>
        </form>

        <div className="mt-6">
          <button
            onClick={handleGoogleSignIn}
            className="w-full bg-red-600 text-white py-2 rounded-md hover:bg-red-700 flex items-center justify-center"
          >
            {/* Beyaz Google ikonu */}
            <svg
              className="h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="48px"
              height="48px"
            >
              <path
                fill="#fff"
                d="M24 9.5c2.75 0 5.25 1 7.25 2.75l5.4-5.4C33.25 3.5 28.75 1.5 24 1.5 14.95 1.5 7.5 8.95 7.5 18S14.95 34.5 24 34.5c5.75 0 10.25-2 13.5-5.75v-9H24v6h11c-1 2.5-4 4-7 4-6 0-10.5-4.5-10.5-10.5S18 9.5 24 9.5z"
              />
              <path
                fill="#fff"
                d="M11.75 19c0-1.5.25-3 1-4.25L7.5 9.5C5.5 12.5 4.5 16 4.5 19.5S5.5 26.5 7.5 29.5l5.25-5.25c-.75-1.25-1-2.75-1-4.25z"
              />
              <path
                fill="#fff"
                d="M24 34.5c2.75 0 5.25-1 7-2.75l-5.25-5.25c-1 .5-2 1-3 1-2.5 0-4.5-1.25-5.75-3l-5.25 5.25c2.5 3.5 6.25 5.75 11.25 5.75z"
              />
              <path
                fill="#fff"
                d="M37.5 18.5H24v6h7.5c-.5 2.25-2 4.25-4.5 5.25v5.25h6c3.5-3.25 5.5-8 5.5-13 0-1-.25-2-.5-2.5z"
              />
            </svg>
            Google ile Giriş Yap
          </button>
        </div>
      </div>
    </div></>
  );
}

export default Login;
