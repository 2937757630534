import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig'; // Firebase yapılandırmasını doğru yolda kontrol edin
import { collection, getDocs } from 'firebase/firestore'; // Firestore fonksiyonları için import
import Hero from './components/Hero'; // Hero bileşeni için import
import FeaturedProducts from './components/FeaturedProducts'; // FeaturedProducts bileşeni için import
import FeaturedPreOrderProducts from './components/FeaturedPreOrderProducts'; // FeaturedPreOrderProducts bileşeni için import
import Footer from './components/Footer'; // Footer bileşeni için import
import Navbar from './Navbar';

function Home() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productCollection = collection(db, 'products');
        const productSnapshot = await getDocs(productCollection);
        const Home = productSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(Home);
      } catch (error) {
        console.error("Ürünler alınırken bir hata oluştu:", error);
      }
    };

    fetchProducts();
  }, []);



  return (
    <>
      <Navbar className="hidden lg:block" />
      <Hero />

      <div className="min-h-screen bg-black">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 md:p-8 lg:p-10">
          <a href="/plp" className="relative block">
            <img
              src="https://sideshow-prod-media.s3.amazonaws.com/ss/media/home-card-grid/hp-b-03-844x1204-300376-VanHelsing.jpg"
              alt="Placeholder"
              className="w-full h-auto"
            />
            <div className="absolute inset-0 bg-black opacity-30"></div>
            <div className="absolute bottom-4 left-4 text-white text-xl md:text-2xl lg:text-3xl font-black">
              TÜM <br /> ÜRÜNLER
            </div>
          </a>

          <a href="#" className="relative block">
            <img
              src="https://sideshow-prod-media.s3.amazonaws.com/ss/media/home-card-grid/hp-b-04-844x1204-200632-Caleb.jpg"
              alt="Placeholder"
              className="w-full h-auto"
            />
            <div className="absolute inset-0 bg-black opacity-30"></div>
            <div className="absolute bottom-4 left-4 text-white text-xl md:text-2xl lg:text-3xl font-black">
              ÖN <br /> SİPARİŞLER
            </div>
          </a>

          <div className="col-span-1 flex flex-col gap-y-2">
            <a href="#" className="relative block">
              <img
                src="https://sideshow-prod-media.s3.amazonaws.com/ss/media/home-card-grid/hp-b-01-844x594-904929-CaptainAmerica.jpg"
                alt="Placeholder"
                className="w-full h-auto"
              />
              <div className="absolute inset-0 bg-black opacity-30"></div>
              <div className="absolute bottom-4 left-4 text-white text-xl md:text-2xl lg:text-3xl font-black">
                ÖN SİPARİŞ <br /> TALEBİ
              </div>
            </a>

            <a href="#" className="relative block">
              <img
                src="https://sideshow-prod-media.s3.amazonaws.com/ss/media/home-card-grid/hp-b-02-844x594-300732-Anakin.jpg"
                alt="Placeholder"
                className="w-full h-auto"
              />
              <div className="absolute inset-0 bg-black opacity-30"></div>
              <div className="absolute bottom-4 left-4 text-white text-xl md:text-2xl lg:text-3xl font-black">
                TAKAS <br /> SEÇENEĞİ
              </div>
            </a>
          </div>
        </div>
      </div>


      <FeaturedProducts products={products} />
      <FeaturedPreOrderProducts products={products} />
      <Footer />
    </>
  );
}

export default Home;
