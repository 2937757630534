import React from 'react';
import { Link } from 'react-router-dom'; // Eğer bir geri dönüş butonu eklemek isterseniz

function ComingSoon() {
  return (
    <div className="min-h-screen bg-[#191919] flex flex-col items-center justify-center text-white">
      {/* Logo */}
      <div className="mb-8">
        <img
                src="https://firebasestorage.googleapis.com/v0/b/elmalicollectibles-87dc4.appspot.com/o/darkec.svg?alt=media&token=3fd6e7b8-10f9-446b-8c34-47dc645e706d"
                alt="Logo"
          className="h-24 w-auto"
        />
      </div>

      {/* Coming Soon Message */}
      <h1 className="text-5xl font-bold mb-4">Yakında Geliyor!</h1>
      <p className="text-lg text-gray-300 mb-8">
        Yeni ve heyecan verici bir deneyim için hazırlanıyoruz. Lütfen bizi takip edin!
      </p>

      {/* Geri dönüş veya ana sayfaya gitmek için bir buton */}
      <Link
        to="/"
        className="py-3 px-6 bg-blue-600 rounded-lg text-white font-semibold hover:bg-blue-700 transition duration-300"
      >
        Ana Sayfaya Dön
      </Link>
    </div>
  );
}

export default ComingSoon;
