import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import Footer from './components/Footer';
import Navbar from './Navbar';

function ProductDetail() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [quantity, setQuantity] = useState(1); // Varsayılan adet 1
  const [isModalOpen, setIsModalOpen] = useState(false); // Resim Modal durumu
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Şu anki resim indeksi

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = doc(db, 'products', productId);
        const productSnapshot = await getDoc(productDoc);

        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();
          setProduct(productData);
          setSelectedImage(productData.coverImageUrl); // Kapak resmi varsayılan olarak ayarlanıyor
        } else {
          console.error("Ürün bulunamadı!");
        }
      } catch (error) {
        console.error("Ürün alınırken hata oluştu:", error);
      }
    };

    fetchProduct();
  }, [productId]);

  // Kapak resmini product.imageUrls dizisinin başına ekle
  const images = product ? [product.coverImageUrl, ...product.imageUrls] : [];

  const handleAddToCart = () => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    
    const itemExists = cartItems.find(item => item.id === productId);
    
    // Stok kontrolü
    if (itemExists) {
      if (itemExists.quantity >= product.stock) {
        Swal.fire('Hata', 'Stokta yeterli ürün bulunmuyor.', 'error');
        return;
      } else {
        itemExists.quantity += quantity; // Ürün sepetteyse miktarı artır
      }
    } else {
      if (quantity > product.stock) {
        Swal.fire('Hata', 'Stokta yeterli ürün bulunmuyor.', 'error');
        return;
      }
      cartItems.push({ ...product, id: productId, quantity });
    }

    localStorage.setItem('cart', JSON.stringify(cartItems));
    Swal.fire('Başarılı!', 'Ürün sepete eklendi!', 'success');
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    setSelectedImage(images[(currentImageIndex + 1) % images.length]);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setSelectedImage(images[(currentImageIndex - 1 + images.length) % images.length]);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const incrementQuantity = () => {
    if (quantity < product.stock) {
      setQuantity(quantity + 1);
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  if (!product) {
    return <p>Yükleniyor...</p>;
  }

  return (
    <>
      <Navbar />

      <section className="py-4 bg-white text-gray-900 md:py-8 xl:py-12 antialiased flex flex-col justify-between min-h-screen">
        <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:space-x-8 px-4">
          {/* Sol Kısım: Ürün Görseli */}
          <div className="lg:w-1/2 w-full flex flex-col items-center lg:items-start">
            <div className="relative w-full h-[300px] md:h-[400px] lg:h-[600px] rounded-lg overflow-hidden mb-4 flex items-center justify-center">
              <button
                className="absolute left-4 text-black text-4xl z-10 bg-white p-3 rounded-full shadow-lg hover:bg-gray-200"
                onClick={handlePrevImage}
              >
                &#8249;
              </button>
              <img 
                className="w-full h-full object-cover cursor-pointer"
                src={selectedImage} 
                alt={product.productName} 
                onClick={() => handleImageClick(currentImageIndex)}
              />
              <button
                className="absolute right-4 text-black text-4xl z-10 bg-white p-3 rounded-full shadow-lg hover:bg-gray-200"
                onClick={handleNextImage}
              >
                &#8250;
              </button>
            </div>
            <div className="flex space-x-2 md:space-x-4 w-full max-w-full overflow-x-auto lg:overflow-x-visible justify-center">
              <ul className="flex flex-row space-x-2 lg:space-x-4">
                {images.map((url, index) => (
                  <li key={index} role="presentation">
                    <button 
                      className={`h-16 md:h-20 w-16 md:w-20 overflow-hidden border-2 rounded-lg p-1 cursor-pointer ${selectedImage === url ? 'border-primary-500' : 'border-gray-200'}`}
                      type="button"
                      onClick={() => {
                        setSelectedImage(url);
                        setCurrentImageIndex(index);
                      }}
                    >
                      <img className="object-cover w-full h-full" src={url} alt={`Ürün Resmi ${index + 1}`} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Sağ Kısım: Ürün Bilgileri */}
          <div className="lg:w-1/2 mt-6 lg:mt-0 flex-shrink-0 px-0 lg:px-4 w-full">
            <h1 className="text-2xl md:text-4xl font-black mb-4">{product.productName}</h1>

            {/* Fiyat */}
            <div className="mt-4">
              <p className="text-2xl md:text-3xl font-extrabold text-black">
                {product.discount > 0 ? (
                  <>
                    <span className="line-through text-red-600">{product.price} ₺</span> 
                    <span className="ml-2 text-black font-extrabold">{(product.price * (100 - product.discount) / 100).toFixed(2)} ₺</span>
                  </>
                ) : (
                  <span>{product.price} ₺</span>
                )}
              </p>
            </div>
            <div className="mt-6">
              <h2 className="text-xl md:text-2xl font-bold">Ürün Detayları</h2>
              <p className="text-sm md:text-base">{product.details}</p>
            </div>

            {/* Ürün Özellikleri */}
            <div className="mt-6">
              <ul className="text-xs md:text-sm text-gray-700 grid grid-cols-2 gap-4 list-disc list-inside">
                <li><strong>Malzeme:</strong> {Array.isArray(product.material) ? product.material.join(', ') : product.material}</li>
                <li><strong>Ölçek:</strong> {Array.isArray(product.scale) ? product.scale.join(', ') : product.scale}</li>
                <li><strong>Ürün Boyutu:</strong> {Array.isArray(product.boyut) ? product.boyut.join(', ') : product.boyut}</li>
                <li><strong>Üretici:</strong> {Array.isArray(product.manufacturer) ? product.manufacturer.join(', ') : product.manufacturer}</li>
                <li><strong>Kategori:</strong> {Array.isArray(product.category) ? product.category.join(', ') : product.category}</li>
                <li><strong>Dünya Limiti:</strong> {Array.isArray(product.limit) ? product.limit.join(', ') : product.limit}</li>
              </ul>
            </div>

            {/* Sepete Ekle ve Adet Seçimi */}
            <div className="flex flex-col md:flex-row gap-4 mt-8 md:mt-10 items-center">
              <div className="flex items-center space-x-4">
                <button
                  className="px-4 py-2 bg-gray-200 rounded-lg text-lg"
                  onClick={decrementQuantity}
                  disabled={quantity <= 1}
                >
                  -
                </button>
                <span className="text-xl font-bold">{quantity}</span>
                <button
                  className="px-4 py-2 bg-gray-200 rounded-lg text-lg"
                  onClick={incrementQuantity}
                  disabled={quantity >= product.stock}
                >
                  +
                </button>
              </div>

              {product.stock > 0 ? (
                <button
                  onClick={handleAddToCart}
                  className="w-full md:w-auto flex items-center justify-center py-2.5 px-5 text-sm font-medium bg-black text-white rounded-lg hover:bg-gray-900"
                >
                  Sepete Ekle
                </button>
              ) : (
                <button
                  onClick={() => {}}
                  className="w-full md:w-auto flex items-center justify-center py-2.5 px-5 text-sm font-medium bg-gray-400 text-white rounded-lg"
                >
                   Gelince haber ver
                </button>
              )}

              <button
                className="w-full md:w-auto flex items-center justify-center py-2.5 px-5 text-sm font-medium bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Favorilere Ekle
              </button>
            </div>
          </div>
        </div>

        {/* Resim Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
            <button
              className="absolute top-4 right-4 text-white text-2xl"
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <button
              className="absolute left-4 text-white text-4xl"
              onClick={handlePrevImage}
            >
              &#8249;
            </button>
            <img
              src={images[currentImageIndex]}
              alt={`Ürün Resmi ${currentImageIndex + 1}`}
              className="max-w-4xl max-h-[80vh] object-contain"
            />
            <button
              className="absolute right-4 text-white text-4xl"
              onClick={handleNextImage}
            >
              &#8250;
            </button>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}

export default ProductDetail;
