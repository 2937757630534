import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProductCard from './components/ProductCard';

function MyCart() {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [originalTotalPrice, setOriginalTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  useEffect(() => {
    // Sepetteki ürünleri local storage'dan çek
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItems(cart);
    calculateTotal(cart);
  }, []);

  const calculateTotal = (cart) => {
    let originalTotal = 0;
    let discountedTotal = 0;

    cart.forEach(item => {
      const itemOriginalTotal = item.price * item.quantity;
      const itemDiscountedTotal = item.discount > 0 
        ? (item.price * (100 - item.discount) / 100) * item.quantity 
        : itemOriginalTotal;

      originalTotal += itemOriginalTotal;
      discountedTotal += itemDiscountedTotal;
    });

    setOriginalTotalPrice(originalTotal.toFixed(2));
    setTotalPrice(discountedTotal.toFixed(2));
    setTotalDiscount((originalTotal - discountedTotal).toFixed(2));
  };

  const handleRemoveFromCart = (productId) => {
    const updatedCart = cartItems.filter(item => item.id !== productId);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    calculateTotal(updatedCart);
    alert('Ürün sepetten kaldırıldı!');
  };

  return (
    <section className="bg-[#191919] py-8 antialiased md:py-16 w-full h-screen">
      <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <div className="mx-auto max-w-3xl">
          <h2 className="text-xl font-semibold text-white sm:text-2xl">Sepetim</h2>

          {cartItems.length > 0 ? (
            <>
              <div className="relative mt-6 overflow-x-auto border-b border-gray-200 dark:border-gray-800 sm:mt-8">
                <table className="w-full text-left text-base text-white">
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-800">
                    {cartItems.map((product) => (
                      <tr key={product.id}>
                        <td className="w-96 min-w-56 whitespace-nowrap py-4">
                          <Link to="#" className="flex items-center gap-4 font-medium hover:underline">
                            <div className="aspect-square h-10 w-10 shrink-0">
                              {/* Eğer product.image boşsa, placeholder resmi göster */}
                              <img 
                                className="h-full w-full object-cover" 
                                src={product.coverImageUrl ? product.coverImageUrl : 'https://via.placeholder.com/150'} 
                                alt={product.productName} 
                              />
                            </div>
                            {product.productName}
                          </Link>
                        </td>

                        <td className="p-4">
                          <div className="flex items-center">
                            <button
                              type="button"
                              className="inline-flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600">
                              <svg className="h-2.5 w-2.5 text-gray-900 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 2">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
                              </svg>
                            </button>
                            <input type="text" value={product.quantity} className="w-10 text-center text-sm font-medium text-white bg-transparent border-0 focus:outline-none" readOnly />
                            <button
                              type="button"
                              className="inline-flex h-5 w-5 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600">
                              <svg className="h-2.5 w-2.5 text-gray-900 dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                              </svg>
                            </button>
                          </div>
                        </td>

                        <td className="p-4 text-end font-bold">{(product.price * product.quantity).toFixed(2)} ₺</td>

                        <td className="py-4">
                          <button onClick={() => handleRemoveFromCart(product.id)} className="ml-auto block rounded-lg p-2 text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-900 dark:text-gray-400">
                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-4">
                <p className="text-xl font-semibold text-white">Sipariş Özeti</p>

                <div className="mt-6 space-y-4">
                  <div className="space-y-2">
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500">İndirimsiz Fiyat</dt>
                      <dd className="text-base font-medium text-white">{originalTotalPrice} ₺</dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500">Toplam İndirim</dt>
                      <dd className="text-base font-medium text-green-500">-{totalDiscount} ₺</dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                      <dt className="text-base font-bold text-white">Toplam</dt>
                      <dd className="text-base font-bold text-white">{totalPrice} ₺</dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex gap-4">
                <Link to="/shop" className="w-full flex items-center justify-center rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700">
                  Alışverişe Devam Et
                </Link>
                <Link to="/checkout" className="w-full flex items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 dark:bg-primary-600 dark:hover:bg-primary-700">
                  Satın Al
                </Link>
              </div>
            </>
          ) : (
            <p className="text-white">Sepetinizde henüz ürün bulunmamaktadır.</p>
          )}
        </div>
      </div>
    </section>
  );
}

export default MyCart;
