import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import ProductDetail from './ProductDetail';
import CreateProduct from './CreateProduct';
import Navbar from './Navbar';
import Register from './Register';
import Login from './Login';
import MyCart from './MyCart';
import Checkout from './Checkout';
import CategoryProducts from './CategoryProducts';
import TradeForm from './TradeForm';
import ProductListPage from './ProductListPage';
import SignInWithPhone from './SignInWithPhone';
import ComingSoon from './components/ComingSoon';
import SearchPage from './SearchPage';

function App() {
  return (
    <Router>
    <div className="bg-[#191919] min-h-screen">
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<MyCart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cp" element={<CreateProduct />} />
          <Route path="/takas" element={<ComingSoon />} />
          <Route path="/plp" element={<ProductListPage />} />
          <Route path="/phone" element={<SignInWithPhone />} />
          <Route path="/search" element={<SearchPage />} />

          <Route path="/product/:productId" element={<ProductDetail />} />
          <Route path="/category/:categoryName" element={<CategoryProducts />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
