import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import ProductCard from './components/ProductCard';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, FunnelIcon } from '@heroicons/react/24/outline';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';

function ProductListPage() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [universes, setUniverses] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [selectedUniverses, setSelectedUniverses] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedScales, setSelectedScales] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [hideOutOfStock, setHideOutOfStock] = useState(false); // Tükenenleri gizle
  const [hidePreOrder, setHidePreOrder] = useState(false); // Ön siparişleri gizle
  const [onlyPreOrder, setOnlyPreOrder] = useState(false); // Sadece ön siparişleri göster
  const [hideInStock, setHideInStock] = useState(false); // Stoktaki ürünleri gizle
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [scales, setScales] = useState([]);
  const [sizes, setSizes] = useState([]);

  const location = useLocation();

  // Firebase'den ürün, filtre kategorilerini çekiyoruz
  useEffect(() => {
    const fetchData = async () => {
      const productsCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productsCollection);
      const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
      setFilteredProducts(productList);

      const maxPriceFromProducts = Math.max(...productList.map(product => product.price));
      setMaxPrice(maxPriceFromProducts);
      setPriceRange([0, maxPriceFromProducts]);

      const uniqueCategories = [...new Set(productList.map(product => product.category))];
      const uniqueManufacturers = [...new Set(productList.map(product => product.manufacturer))];
      setCategories(uniqueCategories);
      setManufacturers(uniqueManufacturers);

      const universesCollection = collection(db, 'universes');
      const universesSnapshot = await getDocs(universesCollection);
      const universesList = universesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUniverses(universesList.map(universe => universe.name));

      const charactersCollection = collection(db, 'characters');
      const charactersSnapshot = await getDocs(charactersCollection);
      const charactersList = charactersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCharacters(charactersList.map(character => character.name));

      const materialsCollection = collection(db, 'materials');
      const materialsSnapshot = await getDocs(materialsCollection);
      const materialsList = materialsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMaterials(materialsList.map(material => material.name));

      const uniqueSizes = [...new Set(productList.map(product => product.sizeRange))];
      const uniqueScales = [...new Set(productList.map(product => product.scale))];
      setSizes(uniqueSizes);
      setScales(uniqueScales);
    };

    fetchData();
  }, []);

  // Ön sipariş sayfasına yönlendirme ile gelen durum
  useEffect(() => {
    if (location.state?.onlyPreOrder) {
      setOnlyPreOrder(true);
    }
  }, [location.state]);

  // Ürünleri filtreleme
  useEffect(() => {
    let tempProducts = products;
  
    if (selectedCategories.length > 0) {
      tempProducts = tempProducts.filter(product =>
        selectedCategories.includes(product.category)
      );
    }
  
    if (selectedManufacturers.length > 0) {
      tempProducts = tempProducts.filter(product =>
        selectedManufacturers.includes(product.manufacturer)
      );
    }
  
    if (selectedUniverses.length > 0) {
      tempProducts = tempProducts.filter(product =>
        selectedUniverses.some(universe => product.universe.includes(universe))
      );
    }
  
    if (selectedCharacters.length > 0) {
      tempProducts = tempProducts.filter(product =>
        selectedCharacters.some(character => product.character.includes(character))
      );
    }
  
    if (selectedMaterials.length > 0) {
      tempProducts = tempProducts.filter(product =>
        selectedMaterials.some(material => product.material.includes(material))
      );
    }
  
    if (selectedScales.length > 0) {
      tempProducts = tempProducts.filter(product =>
        selectedScales.includes(product.scale)
      );
    }
  
    if (selectedSizes.length > 0) {
      tempProducts = tempProducts.filter(product =>
        selectedSizes.includes(product.sizeRange)
      );
    }
  
    tempProducts = tempProducts.filter(
      product => product.price >= priceRange[0] && product.price <= priceRange[1]
    );
  
    if (hideOutOfStock) {
      tempProducts = tempProducts.filter(product => product.stock > 0);
    }
  
    if (hidePreOrder) {
      tempProducts = tempProducts.filter(product => !product.preOrder);
    }
  
    if (onlyPreOrder) {
      tempProducts = tempProducts.filter(product => product.preOrder);
    }
  
    if (hideInStock) {
      tempProducts = tempProducts.filter(product => product.stock === 0);
    }
  
    // Stoğu olmayan ürünleri alta taşıyoruz
    tempProducts = tempProducts.sort((a, b) => {
      if (a.stock === 0 && b.stock > 0) {
        return 1; // Stoğu olmayanı alta taşı
      } else if (a.stock > 0 && b.stock === 0) {
        return -1; // Stoğu olanı üste taşı
      }
      return 0; // Stok durumları aynı ise sıralama yapma
    });
  
    setFilteredProducts(tempProducts);
  }, [
    selectedCategories,
    selectedManufacturers,
    selectedUniverses,
    selectedCharacters,
    selectedMaterials,
    selectedScales,
    selectedSizes,
    priceRange,
    hideOutOfStock,
    hidePreOrder,
    onlyPreOrder,
    hideInStock,
    products,
  ]);
  

  // Çoklu seçimli filtre için yardımcı fonksiyon
  const handleMultipleSelect = (value, setter, selectedValues) => {
    if (selectedValues.includes(value)) {
      setter(selectedValues.filter(val => val !== value));
    } else {
      setter([...selectedValues, value]);
    }
  };

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedManufacturers([]);
    setSelectedUniverses([]);
    setSelectedCharacters([]);
    setSelectedMaterials([]);
    setSelectedScales([]);
    setSelectedSizes([]);
    setPriceRange([0, maxPrice]);
    setHideOutOfStock(false);
    setHidePreOrder(false);
    setOnlyPreOrder(false);
    setHideInStock(false);
  };

  return (
    <>
      <Navbar />
      <div className="bg-[#191919] text-gray-300">
        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">Ürün Listesi</h1>

          <div className="pt-12 lg:grid lg:grid-cols-4 lg:gap-x-8">
            {/* Filtre alanları */}
            <aside className="hidden lg:block">
              <form className="space-y-10 divide-y divide-gray-600">
                <div>
                  <h3 className="font-semibold text-lg text-white">Kategori</h3>
                  <div className="mt-3 space-y-2">
                    {categories.map((category, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedCategories.includes(category)}
                          onChange={() =>
                            handleMultipleSelect(category, setSelectedCategories, selectedCategories)
                          }
                          className="h-4 w-4 rounded border-gray-600 bg-[#2d2d2d] text-indigo-600 focus:ring-indigo-500"
                        />
                        <label className="ml-2 text-sm text-gray-300">{category}</label>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Diğer filtreler: Marka, Evren, Karakter, Boyut, Ölçek, Malzeme */}
                <div>
                  <h3 className="font-semibold text-lg text-white">Marka</h3>
                  <div className="mt-3 space-y-2">
                    {manufacturers.map((manufacturer, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedManufacturers.includes(manufacturer)}
                          onChange={() =>
                            handleMultipleSelect(manufacturer, setSelectedManufacturers, selectedManufacturers)
                          }
                          className="h-4 w-4 rounded border-gray-600 bg-[#2d2d2d] text-indigo-600 focus:ring-indigo-500"
                        />
                        <label className="ml-2 text-sm text-gray-300">{manufacturer}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="font-semibold text-lg text-white">Evren</h3>
                  <div className="mt-3 space-y-2">
                    {universes.map((universe, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedUniverses.includes(universe)}
                          onChange={() =>
                            handleMultipleSelect(universe, setSelectedUniverses, selectedUniverses)
                          }
                          className="h-4 w-4 rounded border-gray-600 bg-[#2d2d2d] text-indigo-600 focus:ring-indigo-500"
                        />
                        <label className="ml-2 text-sm text-gray-300">{universe}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="font-semibold text-lg text-white">Karakter</h3>
                  <div className="mt-3 space-y-2">
                    {characters.map((character, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedCharacters.includes(character)}
                          onChange={() =>
                            handleMultipleSelect(character, setSelectedCharacters, selectedCharacters)
                          }
                          className="h-4 w-4 rounded border-gray-600 bg-[#2d2d2d] text-indigo-600 focus:ring-indigo-500"
                        />
                        <label className="ml-2 text-sm text-gray-300">{character}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="font-semibold text-lg text-white">Boyut Aralığı</h3>
                  <div className="mt-3 space-y-2">
                    {sizes.map((size, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedSizes.includes(size)}
                          onChange={() =>
                            handleMultipleSelect(size, setSelectedSizes, selectedSizes)
                          }
                          className="h-4 w-4 rounded border-gray-600 bg-[#2d2d2d] text-indigo-600 focus:ring-indigo-500"
                        />
                        <label className="ml-2 text-sm text-gray-300">{size}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="font-semibold text-lg text-white">Ölçek</h3>
                  <div className="mt-3 space-y-2">
                    {scales.map((scale, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedScales.includes(scale)}
                          onChange={() =>
                            handleMultipleSelect(scale, setSelectedScales, selectedScales)
                          }
                          className="h-4 w-4 rounded border-gray-600 bg-[#2d2d2d] text-indigo-600 focus:ring-indigo-500"
                        />
                        <label className="ml-2 text-sm text-gray-300">{scale}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="font-semibold text-lg text-white">Malzeme</h3>
                  <div className="mt-3 space-y-2">
                    {materials.map((material, index) => (
                      <div key={index} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedMaterials.includes(material)}
                          onChange={() =>
                            handleMultipleSelect(material, setSelectedMaterials, selectedMaterials)
                          }
                          className="h-4 w-4 rounded border-gray-600 bg-[#2d2d2d] text-indigo-600 focus:ring-indigo-500"
                        />
                        <label className="ml-2 text-sm text-gray-300">{material}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h3 className="font-semibold text-lg text-white">Fiyat Aralığı</h3>
                  <div className="mt-3">
                    <Slider
                      range
                      min={0}
                      max={maxPrice}
                      value={priceRange}
                      onChange={setPriceRange}
                      trackStyle={{ backgroundColor: '#3b82f6' }}
                      handleStyle={{ borderColor: '#3b82f6', backgroundColor: '#3b82f6' }}
                    />
                    <div className="mt-2 flex justify-between text-sm">
                      <span>{priceRange[0]} ₺</span>
                      <span>{priceRange[1]} ₺</span>
                    </div>
                  </div>
                </div>

                {/* Diğer filtreler */}
                <div className="flex flex-col gap-3">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={hideOutOfStock}
                      onChange={(e) => setHideOutOfStock(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-300">Tükenenleri gizle</span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={hidePreOrder}
                      onChange={(e) => setHidePreOrder(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-300">Ön siparişleri gizle</span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={onlyPreOrder}
                      onChange={(e) => setOnlyPreOrder(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-300">Sadece ön siparişleri göster</span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={hideInStock}
                      onChange={(e) => setHideInStock(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-300">Stoktaki ürünleri gizle</span>
                  </label>
                </div>

                <div className="pt-6">
                  <button
                    type="button"
                    onClick={resetFilters}
                    className="w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                  >
                    Filtreleri Sıfırla
                  </button>
                </div>
              </form>
            </aside>

            {/* Ürün Listesi */}
            <div className="mt-6 lg:col-span-3">
              <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <ProductCard key={product.id} product={product} />
                  ))
                ) : (
                  <p className="text-center col-span-full text-gray-500">Ürün bulunamadı.</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Mobil Filtre Butonu */}
        <div className="fixed bottom-5 right-5 lg:hidden">
          <button
            type="button"
            className="flex items-center p-3 bg-blue-600 rounded-full text-white shadow-lg hover:bg-blue-700"
            onClick={() => setMobileFiltersOpen(true)}
          >
            <FunnelIcon className="h-6 w-6 mr-2" />
            Filtreler
          </button>
        </div>

        {/* Mobil Filtre Dialog */}
        <Dialog open={mobileFiltersOpen} onClose={() => setMobileFiltersOpen(false)} className="relative z-40 lg:hidden">
          <div className="fixed inset-0 bg-black bg-opacity-50" />
          <div className="fixed inset-0 z-40 flex">
            <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-[#2d2d2d] py-4 pb-6 shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-white">Filtreler</h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-300"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <aside>
                <form className="space-y-10 divide-y divide-gray-600">
                  {/* Filtreler mobilde */}
                  <div className="pt-6">
                    <button
                      type="button"
                      onClick={resetFilters}
                      className="w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700"
                    >
                      Filtreleri Sıfırla
                    </button>
                  </div>
                </form>
              </aside>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default ProductListPage;
