import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import ProductCard from './components/ProductCard'; // Ürünlerin gösterimi için kullandığınız bileşen

function SearchPage() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    // Firestore'dan ürünleri çekiyoruz
    const fetchProducts = async () => {
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      const productList = productSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productList);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredProducts(products); // Arama yapılmazsa tüm ürünleri göster
    } else {
      const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();
      const searchWords = lowerCaseSearchTerm.split(' '); // Girilen kelimeleri ayır

      const filtered = products.filter((product) => {
        // Ürünün tüm detaylarını bir araya getiriyoruz
        const productDetails = `
          ${product.productName} 
          ${product.details} 
          ${product.material} 
          ${product.scale} 
          ${product.sizeRange} 
          ${product.category} 
          ${product.character?.join(' ')} 
          ${product.universe?.join(' ')} 
          ${product.manufacturer} 
          ${product.tags?.join(' ')}
        `.toLowerCase();

        // Arama kelimelerinin her birinin productDetails içinde olup olmadığını kontrol et
        return searchWords.every(word => productDetails.includes(word));
      });

      setFilteredProducts(filtered);
    }
  }, [searchTerm, products]);

  return (
    <div className="container mx-auto py-6">
      <h1 className="text-2xl font-bold mb-4">Ürün Ara</h1>

      {/* Arama Inputu */}
      <input
        type="text"
        className="w-full p-2 border border-gray-300 rounded-lg"
        placeholder="Ürün arayın..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Arama Sonuçları */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))
        ) : (
          <p className="text-gray-500">Ürün bulunamadı.</p>
        )}
      </div>
    </div>
  );
}

export default SearchPage;
