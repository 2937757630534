import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore/lite';
import { db } from './firebaseConfig';
import ProductCard from './components/ProductCard';

function CategoryProducts() {
  const { categoryName } = useParams();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const q = query(productsCollection, where('category', '==', categoryName));
        const querySnapshot = await getDocs(q);
        const productsList = querySnapshot.docs.map(doc => ({
          id: doc.id,  // ID'yi ekliyoruz
          ...doc.data()
        }));
        setProducts(productsList);
      } catch (error) {
        console.error('Ürünler çekilirken hata oluştu:', error);
      }
    };

    fetchProducts();
  }, [categoryName]);

  return (
    <div className="container mx-auto py-8 px-4 dark:bg-gray-900">
      <h1 className="text-3xl font-semibold text-gray-900 dark:text-white mb-6">
        {categoryName} Ürünleri
      </h1>
      <div className="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
}

export default CategoryProducts;
